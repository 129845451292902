<template>
  <div>
    <b-alert
      show
      variant="warning"
    >
      <div class="alert-body text-center">
        <FeatherIcon
          icon="InfoIcon"
          size="24"
        />
        <p>{{ text }}</p>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'EmptyAlert',
  components: {
    BAlert,
  },
  props: {
    text: {
      Type: String,
      Default: 'Kayıt bulunamadı.',
    },
  },
}
</script>

<style scoped></style>
