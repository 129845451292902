<template>
  <div>
    <b-card title="İş Sağlığı ve Güvenliği Eğitimleri">
      <div v-if="isgEducations.length > 0">
        <b-list-group
          v-for="(item,key) in isgEducations"
          :key="key"
        >
          <a
            v-b-modal.modal-isg-education
            target="_blank"
            @click="setModal(item)"
          >
            <b-list-group-item>
              <b-row class="d-flex align-items-center">
                <b-col>
                  {{ item.title }}
                  <div class="text-muted">
                    <small>
                      {{ item.content }}
                    </small>
                  </div>
                </b-col>
                <b-col
                  cols="auto"
                  style="padding-top: 8px"
                >
                  <FeatherIcon
                    icon="DownloadIcon"
                    size="24"
                  />
                </b-col>
              </b-row>
            </b-list-group-item>
          </a>
        </b-list-group>
      </div>
      <empty-alert
        v-else
        text="Henüz iş sağlığı ve güvenliği eğitimi bulunmamaktadır."
      />
    </b-card>
    <b-modal
      id="modal-isg-education"
      centered
      size="xl"
      :title="modalData.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="font-medium-4 mb-2">
          {{ modalData.content }}
        </div>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="folderUrl + modalData.file"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol, BModal, BCardText, BEmbed,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'IsgEducations',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BModal,
    BCardText,
    BEmbed,
    EmptyAlert,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/fixed_educations/`,
      modalData: {
        title: null,
        content: null,
        file: null,
      },
    }
  },
  computed: {
    isgEducations() {
      return this.$store.getters['fixedEducations/getIsgEducations']
    },
  },
  created() {
    this.getIsgEducations()
  },
  methods: {
    getIsgEducations() {
      this.$store.dispatch('fixedEducations/isgEducations')
    },
    setModal(data) {
      this.modalData = {
        title: data.title,
        content: data.content,
        file: data.file,
      }
    },
  },
}
</script>
<style scoped></style>
