<template>
  <div>
    <b-card-code title="Eğitim Takvimi">
      <app-timeline v-if="eduCalendars.length > 0">
        <app-timeline-item
          v-for="(event) in eduCalendars"
          :key="event.id"
          :title="event.title"
          :subtitle="event.content"
          :time="moment(event.edate).format('DD.MM.YYYY')"
        />
      </app-timeline>
      <empty-alert
        v-else
        text="Henüz planlanmış eğitim bulunmamaktadır."
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'EduCalendar',
  components: {
    BCardCode,
    AppTimeline,
    AppTimelineItem,
    EmptyAlert,
  },
  computed: {
    eduCalendars() {
      return this.$store.getters['eduCalendar/getEduCalendars']
    },
  },
  created() {
    this.getEduCalendars()
  },
  methods: {
    getEduCalendars() {
      this.$store.dispatch('eduCalendar/eduCalendars')
    },
  },
}
</script>

<style scoped></style>
