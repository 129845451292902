<template>
  <div>
    <b-card title="Yeni Başlayan Personel">
      <b-list-group
        v-for="(item,key) in newEmployeeEducations"
        :key="key"
      >
        <a
          v-b-modal.modal-new-educations
          target="_blank"
          @click="setModal(item)"
        >
          <b-list-group-item>
            <b-row class="d-flex align-items-center">
              <b-col>
                {{ item.title }}
                <div
                  v-if="item.content"
                  class="text-muted"
                >
                  <small>
                    {{ item.content }}
                  </small>
                </div>
              </b-col>
              <b-col
                cols="auto"
                style="padding-top: 8px"
              >
                <FeatherIcon
                  icon="DownloadIcon"
                  size="24"
                />
              </b-col>
            </b-row>
          </b-list-group-item>
        </a>
      </b-list-group>
    </b-card>
    <b-modal
      id="modal-new-educations"
      centered
      size="xl"
      :title="modalData.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="font-medium-4 mb-2">
          {{ modalData.content }}
        </div>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="folderUrl + modalData.file"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol, BModal, BCardText, BEmbed,
} from 'bootstrap-vue'

export default {
  name: 'NewEmployeeEducations',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BModal,
    BCardText,
    BEmbed,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/fixed_educations/`,
      modalData: {
        title: null,
        content: null,
        file: null,
      },
    }
  },
  computed: {
    newEmployeeEducations() {
      return this.$store.getters['fixedEducations/getNewEmployeeEducations']
    },
  },
  created() {
    this.getNewEmployeeEducations()
  },
  methods: {
    getNewEmployeeEducations() {
      this.$store.dispatch('fixedEducations/newEmployeeEducations')
    },
    setModal(data) {
      this.modalData = {
        title: data.title,
        content: data.content,
        file: data.file,
      }
    },
  },
}
</script>
<style scoped></style>
