<template>
  <div>
    <b-card title="Geçmiş Eğitimler">
      <b-table-simple
        v-if="educations.length > 0"
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th>Eğitim Adı</b-th>
            <b-th
              width="180"
              class="text-center"
            >
              Eğitim Tarihi
            </b-th>
            <b-th
              width="140"
              class="text-center"
            >
              <FeatherIcon icon="DownloadIcon" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="education in educations"
            :key="education.id"
          >
            <b-td>
              {{ education.title }}
              <div class="text-muted">
                <small>{{ education.content }}</small>
              </div>
            </b-td>
            <b-td class="text-center">
              {{ moment(education.edate).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="text-center">
              <b-button
                :href="folderUrl + education.file"
                target="_blank"
                variant="primary"
                size="sm"
              >
                <FeatherIcon icon="DownloadIcon" />
                İndir
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <empty-alert
        v-else
        text="Henüz geçmiş eğitim bulunmamaktadır."
      />
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BButton,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'EducationArchive',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BButton,
    EmptyAlert,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/educations/`,
    }
  },
  computed: {
    educations() {
      return this.$store.getters['educations/getEducations']
    },
  },
  created() {
    this.getEducations()
  },
  methods: {
    getEducations() {
      this.$store.dispatch('educations/educations')
    },
  },
}
</script>

<style scoped>

</style>
