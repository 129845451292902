<template>
  <div>
    <b-card
      title="Santral Kullanımı"
    >
      <b-row>
        <b-col
          v-for="item in dataList"
          :key="item.id"
        >
          <div
            class="py-4 bg-light rounded text-center cursor-pointer"
            @click="openModal(item)"
          >
            <div class="font-small-2 text-warning">
              <FeatherIcon
                icon="PlayCircleIcon"
                size="34"
              />
            </div>
            <div class="mt-1">
              {{ item.title }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="modalData.status"
      size="lg"
      centered
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      :title="modalData.title"
    >
      <b-embed
        type="video"
        aspect="4by3"
        controls
      >
        <source
          :src="modalData.video"
          type="video/mp4"
        >
      </b-embed>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BModal, BEmbed,
} from 'bootstrap-vue'

export default {
  name: 'SwitchBoardVideos',
  components: {
    BCard, BRow, BCol, BModal, BEmbed,
  },
  data() {
    return {
      modalData: {
        status: false,
        title: null,
        video: null,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['educations/getSwitchboard']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    openModal(video) {
      this.modalData.status = true
      this.modalData.title = video.title
      this.modalData.video = video.filename
    },
    getDataList() {
      this.$store.dispatch('educations/switchboard')
    },
  },
}
</script>
