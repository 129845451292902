<template>
  <b-row class="match-height">
    <b-col
      cols="12"
      md="4"
    >
      <edu-calendar />
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <switchboard-videos />
      <new-employee-educations />
      <isg-educations />
      <education-archive />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import EduCalendar from '@/views/Portal/Education/Edu_calendar.vue'
import NewEmployeeEducations from '@/views/Portal/Education/NewEmployeeEducations.vue'
import IsgEducations from '@/views/Portal/Education/IsgEducations.vue'
// import KvkkEducations from '@/views/Portal/Education/KvkkEducations.vue'
import EducationArchive from '@/views/Portal/Education/EducationArchive.vue'
import SwitchboardVideos from '@/views/Portal/Education/SwitchboardVideos.vue'

export default {
  name: 'Company',
  components: {
    BRow,
    BCol,
    EduCalendar,
    NewEmployeeEducations,
    IsgEducations,
    // KvkkEducations,
    EducationArchive,
    SwitchboardVideos,
  },
}
</script>

<style scoped>

</style>
